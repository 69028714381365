/* S of Pretendard */
@font-face {
  font-family: "SlidTextStyle";
  font-style: normal;
  font-weight: 400;
  src: url("/fonts/Pretendard/Pretendard-Regular.eot");
  src: url("/fonts/Pretendard/Pretendard-Regular.eot?#iefix") format("embedded-opentype"), url("/fonts/Pretendard/Pretendard-Regular.woff2") format("woff2"),
    url("/fonts/Pretendard/Pretendard-Regular.woff") format("woff"), url("/fonts/Pretendard/Pretendard-Regular.ttf") format("truetype"),
    url("/fonts/Pretendard/Pretendard-Regular.svg#Pretendard") format("svg");
}

@font-face {
  font-family: "SlidTextStyle";
  font-style: normal;
  font-weight: 500;
  src: url("/fonts/Pretendard/Pretendard-Medium.eot");
  src: url("/fonts/Pretendard/Pretendard-Medium.eot?#iefix") format("embedded-opentype"), url("/fonts/Pretendard/Pretendard-Medium.woff2") format("woff2"),
    url("/fonts/Pretendard/Pretendard-Medium.woff") format("woff"), url("/fonts/Pretendard/Pretendard-Medium.ttf") format("truetype"),
    url("/fonts/Pretendard/Pretendard-Medium.svg#Pretendard") format("svg");
}

@font-face {
  font-family: "SlidTextStyle";
  font-style: normal;
  font-weight: 700;
  src: url("/fonts/Pretendard/Pretendard-Bold.eot");
  src: url("/fonts/Pretendard/Pretendard-Bold.eot?#iefix") format("embedded-opentype"), url("/fonts/Pretendard/Pretendard-Bold.woff2") format("woff2"),
    url("/fonts/Pretendard/Pretendard-Bold.woff") format("woff"), url("/fonts/Pretendard/Pretendard-Bold.ttf") format("truetype"), url("/fonts/Pretendard/Pretendard-Bold.svg#Pretendard") format("svg");
}
/* E of Pretendard */

/* for landing page */
/* S of Poppins */
@font-face {
  font-family: "SlidLandingTextStyle";
  font-style: normal;
  font-weight: 400;
  src: url("/fonts/Poppins/Poppins-Regular.woff2") format("woff2"), url("/fonts/Poppins/Poppins-Regular.woff") format("woff"), url("/fonts/Poppins/Poppins-Regular.ttf") format("truetype");
}

@font-face {
  font-family: "SlidLandingTextStyle";
  font-style: normal;
  font-weight: 700;
  src: url("/fonts/Poppins/Poppins-Bold.woff2") format("woff2"), url("/fonts/Poppins/Poppins-Bold.woff") format("woff"), url("/fonts/Poppins/Poppins-Bold.ttf") format("truetype");
}

/* E of Poppins */

/* S of Pretendard */
@font-face {
  font-family: "SlidLandingTextStyle";
  font-style: normal;
  font-weight: 400;
  src: url("/fonts/Pretendard/Pretendard-Regular.eot");
  src: url("/fonts/Pretendard/Pretendard-Regular.eot?#iefix") format("embedded-opentype"), url("/fonts/Pretendard/Pretendard-Regular.woff2") format("woff2"),
    url("/fonts/Pretendard/Pretendard-Regular.woff") format("woff"), url("/fonts/Pretendard/Pretendard-Regular.ttf") format("truetype"),
    url("/fonts/Pretendard/Pretendard-Regular.svg#Pretendard") format("svg");
  unicode-range: U+3131-318E, U+AC00-D7A3;
}

@font-face {
  font-family: "SlidLandingTextStyle";
  font-style: normal;
  font-weight: 500;
  src: url("/fonts/Pretendard/Pretendard-Medium.eot");
  src: url("/fonts/Pretendard/Pretendard-Medium.eot?#iefix") format("embedded-opentype"), url("/fonts/Pretendard/Pretendard-Medium.woff2") format("woff2"),
    url("/fonts/Pretendard/Pretendard-Medium.woff") format("woff"), url("/fonts/Pretendard/Pretendard-Medium.ttf") format("truetype"),
    url("/fonts/Pretendard/Pretendard-Medium.svg#Pretendard") format("svg");
  unicode-range: U+3131-318E, U+AC00-D7A3;
}

@font-face {
  font-family: "SlidLandingTextStyle";
  font-style: normal;
  font-weight: 700;
  src: url("/fonts/Pretendard/Pretendard-Bold.eot");
  src: url("/fonts/Pretendard/Pretendard-Bold.eot?#iefix") format("embedded-opentype"), url("/fonts/Pretendard/Pretendard-Bold.woff2") format("woff2"),
    url("/fonts/Pretendard/Pretendard-Bold.woff") format("woff"), url("/fonts/Pretendard/Pretendard-Bold.ttf") format("truetype"), url("/fonts/Pretendard/Pretendard-Bold.svg#Pretendard") format("svg");
  unicode-range: U+3131-318E, U+AC00-D7A3;
}
/* E of Pretendard */
