/**
 * Block Tool wrapper
 */
.cdx-block {
  &::-webkit-input-placeholder {
    line-height: normal !important;
  }
}

/**
 * Input
 */
.cdx-input {
  border: 1px solid var(--color-gray-border);
  box-shadow: inset 0 1px 2px 0 rgba(35, 44, 72, 0.06);
  border-radius: 3px;
  padding: 10px 12px;
  outline: none;
  width: 100%;
  box-sizing: border-box;

  /**
   * Workaround Firefox bug with cursor position on empty content editable elements with ::before pseudo
   * https://bugzilla.mozilla.org/show_bug.cgi?id=904846
   */
  &[data-placeholder]::before {
    position: static !important;
    display: inline-block;
    width: 0;
    white-space: nowrap;
    pointer-events: none;
  }
}

/**
 * Settings
 */
.cdx-settings-button {
  @apply --toolbar-button;

  &:not(:nth-child(3n + 3)) {
    margin-right: 3px;
  }

  &:nth-child(n + 4) {
    margin-top: 3px;
  }

  &--active {
    color: var(--color-active-icon);
  }
}

/**
 * Loader
 */
.cdx-loader {
  position: relative;
  border: 1px solid var(--color-gray-border);

  &::before {
    content: "";
    position: absolute;
    left: 50%;
    top: 50%;
    width: 18px;
    height: 18px;
    margin: -11px 0 0 -11px;
    border: 2px solid var(--color-gray-border);
    border-left-color: var(--color-active-icon);
    border-radius: 50%;
    animation: cdxRotation 1.2s infinite linear;
  }
}

@keyframes cdxRotation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

/**
 * Button
 */
.cdx-button {
  padding: 13px;
  border-radius: 3px;
  border: 1px solid var(--color-gray-border);
  font-size: 1.5rem;
  background: #fff;
  box-shadow: 0 2px 2px 0 rgba(18, 30, 57, 0.04);
  color: var(--grayText);
  text-align: center;
  cursor: pointer;

  &:hover {
    background: #fbfcfe;
    box-shadow: 0 1px 3px 0 rgba(18, 30, 57, 0.08);
  }

  svg {
    height: 20px;
    margin-right: 0.2em;
    margin-top: -2px;
  }
}
