.tooltip-inner {
  background-color: var(--gray17) !important;
  font-size: 1.2rem !important;
  font-weight: 700;
  padding: 4px 12px !important;
  border-radius: 8px !important;
}

.tooltip-arrow::before {
  border-bottom-color: var(--gray17) !important;
}

.tooltip {
  opacity: 0.9;
  z-index: 99999;
}
