@keyframes fade-in {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

.ce-block {
  animation: fade-in 300ms ease;
  animation-fill-mode: initial;

  &:first-of-type {
    margin-top: 0;
  }

  &--selected &__content {
    background: var(--selectionColor);

    /**
     * Workaround Safari case when user can select inline-fragment with cross-block-selection
     */
    & [contenteditable] {
      -webkit-user-select: none;
      user-select: none;
    }

    img,
    .ce-stub {
      opacity: 0.55;
    }
  }

  &--stretched &__content {
    max-width: none;
  }

  &__content {
    position: relative;
    max-width: var(--content-width);
    margin: 0 auto;
    transition: background-color 150ms ease;
  }

  &--drop-target &__content {
    &:before {
      content: '';
      position: absolute;
      top: 100%;
      left: -20px;
      margin-top: -1px;
      height: 8px;
      width: 8px;
      border: solid var(--color-active-icon);
      border-width: 1px 1px 0 0;
      transform-origin: right;
      transform: rotate(45deg);
    }

    &:after {
      content: '';
      position: absolute;
      top: 100%;
      height: 1px;
      width: 100%;
      color: var(--color-active-icon);
      background: repeating-linear-gradient(
        90deg,
        var(--color-active-icon),
        var(--color-active-icon) 1px,
        #fff 1px,
        #fff 6px
      );
    }
  }

  a {
    cursor: pointer;
    text-decoration: underline;
  }

  b {
    font-weight: bold;
  }

  i {
    font-style: italic;
  }
}

.codex-editor--narrow .ce-block--focused {
  @media (--not-mobile) {
    margin-right: calc(var(--narrow-mode-right-padding) * -1);
    padding-right: var(--narrow-mode-right-padding);
  }
}
