html,
body,
#root,
#__next,
.App {
  height: 100%;
  min-height: -webkit-fill-available;
  scroll-behavior: smooth;
  overflow-x: hidden;
  white-space: pre-line;
  margin: 0;
}

.dropup,
.dropup .dropdown-toggle {
  height: 100%;
}

.dropdown-toggle::after {
  display: none !important;
}

.btn:focus {
  box-shadow: none;
}

.slid-font {
  font-family: "SlidTextStyle", -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif !important;
}

.App-Component.error-container {
  font-size: 1em;
  height: 100vh;
}

.App-Component.error-container .loading-container,
.App-Component.error-container .loading-content-container,
.App-Component.error-container .loading-sample-container {
  height: 100vh;
}

.App-Component.error-container .error-content-container {
  font-size: 1.5em;
}

.App-Component.error-container .loading-sample-container .text-muted {
  font-size: 0.8em;
}

.App-Component.error-container .logo {
  height: 60px;
}

.App-Component.error-container .logo-text {
  width: 100px;
}

.App-Component.error-container .slid-sample-gif {
  width: 600px;
}

.main-loading-container {
  height: 100vh;
}

.main-loading-container .logo {
  height: 60px;
}

.main-loading-container .logo-text {
  width: 100px;
}

.pointer {
  cursor: pointer;
}

.custom-control {
  user-drag: none;
  user-select: none;
  -moz-user-select: none;
  -webkit-user-drag: none;
  -webkit-user-select: none;
  -ms-user-select: none;
}

.custom-control-right {
  padding-right: 40px;
  padding-left: 0;
  margin-left: 0;
  margin-right: 0;
}
.custom-control-right .custom-control-label::after {
  right: -1.5rem;
  left: auto;
}
.custom-control-right .custom-control-label::before {
  right: -2.35rem;
  left: auto;
}
.icon-desc-text {
  font-size: 0.8em;
  font-weight: bold;
}

.vdocs-container {
  width: 100vw;
  height: 100vh;
}

.vdocs-container p {
  font-size: 1.3em;
}
