.loader-placeholder {
  padding-top: 20% !important;
  padding-bottom: 20% !important;
  width: 100%;
  max-height: 375px;
  background: #292929;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.loader-header {
  font-style: normal;
  font-weight: 500;
  font-size: 2.4rem;
  color: #ffffff;
}

.countdown-red {
  color: #ff0000;
}

.loader-body {
  font-style: normal;
  font-weight: 300;
  font-size: 1.9rem;
  color: #ffffff;
}

.loader-body-additional-text {
  color: #646464;
}

.video-block {
  outline: none;
}

.video-block.height-placeholder {
  padding-top: 56.25% !important;
  border: 4px solid #dcdcdc;
  border-radius: 5px;
}

.video-block video {
  outline: 1px solid var(--gray3);
  background-color: var(--gray1);
  object-fit: contain;
  width: 100%;
  /* max-height: 475px; */
  max-height: 375px;
}

@media print {
  .video-block video {
    display: none;
    max-height: unset;
    border: unset;
    background: unset;
    max-height: 150mm;
  }

  @media (orientation: landscape) {
    .video-block video {
      max-height: 180mm;
    }
  }
}

.cdx-unordred-list-outer-wrapper {
  max-width: 100%;
}

.cdx-unordred-list-inner-wrapper {
  display: flex;
  align-items: flex-start;
  width: 100%;
  padding-left: 2px;
  color: inherit;
  fill: inherit;
}

.cdx-unordred-list__pseudo-selection {
  background: transparent;
  margin-right: 3px;
  width: 16px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex-grow: 0;
  flex-shrink: 0;
  min-height: calc(1.5em + 3px + 3px);
}

.cdx-blounordredck-list-settings {
  display: flex;
}
.cdx-unordred-list-settings .cdx-settings-button {
  width: 50%;
}

.cdx-unordred-list___input-wrapper {
  display: flex;
  flex-direction: row;
  width: 100%;
}

.cdx-unordred-list__pseudo-before {
  line-height: 1;
  margin-bottom: 0.1em;
  font-size: 1.5em;
}

.cdx-unordred-list__pseudo-before::before {
  line-height: 1;
  margin-bottom: 0.1em;
  content: attr(data-before);
}

.cdx-unordred-list__input {
  max-width: 100%;
  width: 100%;
  white-space: pre-wrap;
  word-break: break-word;
  caret-color: rgb(55, 53, 47);
  text-align: left;
  outline: none;
}

.ce-paragraph {
  line-height: 1.6em;
  outline: none;
  white-space: pre-wrap;
}

.ce-paragraph[data-placeholder]:empty::before {
  content: attr(data-placeholder);
  color: #707684;
  font-weight: normal;
  opacity: 0;
}

/** Show placeholder at the first paragraph if Editor is empty */
.codex-editor--empty .ce-block:first-child .ce-paragraph[data-placeholder]:empty::before {
  opacity: 1;
}

.codex-editor--toolbox-opened .ce-block:first-child .ce-paragraph[data-placeholder]:empty::before,
.codex-editor--empty .ce-block:first-child .ce-paragraph[data-placeholder]:empty:focus::before {
  opacity: 0;
}

.ce-paragraph p:first-of-type {
  margin-top: 0;
}

.ce-paragraph p:last-of-type {
  margin-bottom: 0;
}

.cdx-ordered-list-outer-wrapper {
  max-width: 100%;
  margin-top: 1px;
  margin-bottom: 1px;
}

.cdx-ordered-list-inner-wrapper {
  display: flex;
  align-items: flex-start;
  width: 100%;
  padding-left: 2px;
  color: inherit;
  fill: inherit;
}

.cdx-ordered-list__pseudo-selection {
  background: transparent;
  margin-right: 3px;
  width: 16px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex-grow: 0;
  flex-shrink: 0;
  min-height: calc(1.5em + 3px + 3px);
}

.cdx-bloorderedck-list-settings {
  display: flex;
}
.cdx-ordered-list-settings .cdx-settings-button {
  width: 50%;
}

.cdx-ordered-list___input-wrapper {
  display: flex;
  flex-direction: row;
  width: 100%;
}

.cdx-ordered-list__pseudo-before {
  line-height: 1;
  margin-bottom: 0.1em;
}

.cdx-ordered-list__pseudo-before::before {
  line-height: 1;
  margin-bottom: 0.1em;
  content: attr(data-before);
}

.cdx-ordered-list__input {
  max-width: 100%;
  width: 100%;
  white-space: pre-wrap;
  word-break: break-word;
  caret-color: rgb(55, 53, 47);
  text-align: left;
  outline: none;
}

.cdx-nested-list {
  margin: 0;
  padding: 0;
  outline: none;
  counter-reset: item;
  list-style: none;
}
.cdx-nested-list__item {
  line-height: 1.6em;
  display: flex;
  margin: 5px 30px;
}
.cdx-nested-list__item [contenteditable] {
  outline: none;
}
.cdx-nested-list__item-body {
  flex-grow: 2;
}
.cdx-nested-list__item-content,
.cdx-nested-list__item-children {
  flex-basis: 100%;
}
.cdx-nested-list__item-content {
  word-break: break-word;
  white-space: pre-wrap;
}
.cdx-nested-list__item::before {
  counter-increment: item;
  margin-right: 5px;
  white-space: nowrap;
}
.cdx-nested-list--ordered > .cdx-nested-list__item::before {
  content: counters(item, ".") ". ";
}
.cdx-nested-list--unordered > .cdx-nested-list__item::before {
  content: "•";
}
.cdx-nested-list__settings {
  display: flex;
}
.cdx-nested-list__settings .cdx-settings-button {
  width: 50%;
}

.cdx-loader {
  padding-top: 56.25%;
}

.cdx-list {
  margin: 0;
  padding-left: 40px;
  outline: none;
}
.cdx-list__item {
  padding: 5.5px 0 5.5px 3px;
  line-height: 1.6em;
}
.cdx-list--unordered {
  list-style: disc;
}
.cdx-list--ordered {
  list-style: decimal;
}
.cdx-list-settings {
  display: flex;
}
.cdx-list-settings .cdx-settings-button {
  width: 50%;
}

/**
 * Plugin styles
 */
.ce-header {
  padding: 1em 0;
  margin: 0;
  margin-bottom: -0.9em;
  line-height: 1.5em;
  outline: none;
}

.ce-header p,
.ce-header div {
  padding: 0 !important;
  margin: 0 !important;
}

.ce-header[contentEditable="true"][data-placeholder]::before {
  position: absolute;
  content: attr(data-placeholder);
  color: #707684;
  font-weight: normal;
  display: none;
  cursor: text;
}

.ce-header[contentEditable="true"][data-placeholder]:empty::before {
  display: block;
}

.ce-header[contentEditable="true"][data-placeholder]:empty:focus::before {
  display: none;
}

.cdx-block-list {
  margin: 0;
  outline: none;

  padding-top: 2.5px;
  padding-bottom: 2.5px;
}
.cdx-block-list__item {
  padding: 0 0 0 0;
  line-height: 1.6em;
}
.cdx-block-list--unordered {
  /* list-style: disc; */
  list-style: none;
}
.cdx-block-list--unordered > .cdx-block-list__item::before {
  margin-right: 5px;
  content: "•";
}
.cdx-block-list--ordered {
  list-style: decimal;
}
.cdx-block-list-settings {
  display: flex;
}
.cdx-block-list-settings .cdx-settings-button {
  width: 50%;
}

.math-input-wrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 5px;
}
.math-preview {
  min-height: 50px;
  width: 100%;
  padding: 10px;
  outline: 1px solid var(--gray3);
  font-size: 20px;
  text-align: center;
  margin: 10px 0;
  overflow-x: auto;
}
.math-preview::-webkit-scrollbar {
  height: 7px;
}
.math-preview::-webkit-scrollbar-thumb {
  background-color: var(--gray5);
  border-radius: 10px;
  background-clip: padding-box;
  border: 2px solid transparent;
}
.math-preview * {
  font-family: "Katex_Math";
}
.math-input:focus-visible {
  border-radius: none;
  outline: 2px solid var(--blue7);
}
.math-input {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border: none;
  outline: 2px solid var(--blue7);
  background: none;
  width: 100%;
  padding: 10px;
  margin-top: 5px;
  border-radius: 0;
  color: var(--gray15);
}

.math-input-hide {
  display: none !important;
}

.errorMessage {
  color: red;
}

.ce-image-loader__input {
  display: none;
}

.ce-image-loader__button {
  width: 100%;
}

.image-block {
  outline: 1px solid var(--gray3);
  display: flex;
  flex-direction: column;
  cursor: pointer;
}

.image-block.height-placeholder {
  padding-top: 56.25% !important;
  border: 3px solid #dcdcdc;
  border-radius: 5px;
}

.image-block img {
  border: 3px solid #dcdcdc;
  background-color: #f7f7f9;
  border-radius: 5px;
  object-fit: contain;
  width: 100%;
  max-height: 375px;
  position: relative;
}

.image-hover-button__container {
  position: absolute;
  top: 13px;
  right: 7px;
  display: flex;
  align-items: center;
  justify-content: center;
  display: none;
}

.image-hover-button__item {
  width: 28px;
  height: 28px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(33, 37, 41, 0.8);
  cursor: pointer;
  border-right: 1px solid var(--gray9);
}

.image-hover-button__item:focus {
  outline: 0;
  border: none;
}

.image-hover-button__item:first-child {
  border-radius: 4px 0 0 4px;
}

.image-hover-button__item:last-child {
  border-right: none;
  border-radius: 0 4px 4px 0;
}

.image-hover-button__item:hover {
  background-color: rgba(33, 37, 41, 0.9);
}

.image-hover-button__icon-container {
  display: flex;
  align-items: center;
  justify-content: center;
}

.image-hover-button__icon-container svg {
  fill: #fff;
}

.image-hover-button__container.image-hover-button__shown {
  display: inherit;
}

#checkmarkWrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  position: absolute;
  right: 0px;
  bottom: -8.5px;
  display: flex;
  gap: 3px;
}

.checkmark__circle {
  stroke-dasharray: 166;
  stroke-dashoffset: 166;
  stroke-width: 3;
  stroke-miterlimit: 10;
  stroke: var(--blue8);
  fill: none;
  animation: stroke 0.6s cubic-bezier(0.65, 0, 0.45, 1) forwards;
}

.checkmark {
  width: 12px;
  height: 12px;
  border-radius: 50%;
  display: block;
  stroke-width: 3;
  stroke: #fff;
  stroke-miterlimit: 10;
  margin: 10% auto;
  box-shadow: inset 0px 0px 0px var(--blue8);
  animation: fill 0.4s ease-in-out 0.4s forwards, scale 0.3s ease-in-out 0.9s both;
}

.checkmark__check {
  transform-origin: 50% 50%;
  stroke-dasharray: 48;
  stroke-dashoffset: 48;
  animation: stroke 0.3s cubic-bezier(0.65, 0, 0.45, 1) 0.8s forwards;
}
.checkmark__statement {
  color: var(--blue8);
  font-size: 11px !important;
  font-weight: 700;
  display: flex;
  align-items: center;
}
@keyframes stroke {
  100% {
    stroke-dashoffset: 0;
  }
}

@keyframes scale {
  0%,
  100% {
    transform: none;
  }

  50% {
    transform: scale3d(1.1, 1.1, 1);
  }
}

@keyframes fill {
  100% {
    box-shadow: inset 0px 0px 0px 30px var(--blue8);
  }
}
.upload-loading-statement {
  font-size: 11px !important;
  color: var(--gray13);
  display: flex;
  align-items: center;
  justify-content: center;
  user-select: none;
  -webkit-user-drag: none;
}
.image-block .img-upload-loader-wrapper {
  position: absolute;
  right: 0px;
  bottom: -8.5px;
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  gap: 3px;
}
@media print {
  .image-block img {
    max-height: unset;
    border: unset;
    background: unset;
    max-height: 150mm;
  }

  @media (orientation: landscape) {
    .image-block img {
      max-height: 180mm;
    }
  }
}
.spinner-border-sm {
  width: 11px;
  height: 11px;
  color: var(--gray9);
  border-width: 0.15em;
}

.break-line {
  outline: none;
  height: 25px;
  display: flex;
  align-content: center;
}

.break-line__divider {
  height: 0px;
  width: 100%;
  padding: 0;
  border-top: 2px solid var(--gray3);
  align-self: center;
  display: block;
}

.CodeMirror * {
  font-family: "SlidTextStyle", -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif !important;
  font-size: 1.6rem;
}

.CodeMirror {
  border: 1px solid var(--gray3);
  border-top: none;
  z-index: 0;
}

.cdx-codemirror__selectwrapper__Dark {
  display: flex;
  justify-content: space-between;
  background-color: var(--gray15);
  border: 1px solid 282a36;
  border-bottom: none;
  padding: 7px 12px;
  padding-bottom: 10px;
  color: var(--gray9) !important;
}

.cdx-codemirror__selectwrapper__Light {
  display: flex;
  justify-content: space-between;
  background-color: var(--gray1);
  border: 1px solid var(--gray3);
  border-bottom: none;
  padding: 7px 12px;
  padding-bottom: 10px;
  color: var(--gray7);
}

.cdx-codemirror__language {
  cursor: pointer;
  padding-right: 2em;
  border: none;
  background: transparent;
  background-image: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  text-indent: 0.01px;
  text-overflow: "";
  font-size: 1.6rem;
  color: inherit;
}

.cdx-codemirror__language:focus {
  border: 0;
  outline: 0;
}

.cdx-codemirror__language:active {
  border: 0;
  outline: 0;
}

.cdx-codemirror__themeSelect {
  cursor: pointer;
  border: none;
  background: transparent;
  background-image: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  text-indent: 0.01px;
  text-overflow: "";
  font-size: 1.6rem;
  direction: rtl;
  color: inherit;
}

.cdx-codemirror__themeSelect:focus {
  border: 0;
  outline: 0;
}

.cdx-codemirror__themeSelect:active {
  border: 0;
  outline: 0;
}

.CodeMirror-gutters {
  border-right: 1px solid var(--gray3) !important;
  background-color: var(--gray1) !important;
}

.CodeMirror-linenumber {
  color: var(--gray7) !important;
}

.cm-s-dracula .CodeMirror-linenumber {
  color: var(--gray9) !important;
}

.ReactCodeMirror .cm-s-dracula .CodeMirror-gutters {
  background-color: var(--gray15) !important;
  color: var(--gray9) !important;
  border-right: 1px solid var(--gray13) !important;
}

.ReactCodeMirror .cm-s-dracula.CodeMirror {
  background-color: var(--gray17) !important;
}

.ce-code__textarea {
  min-height: 150px;
  color: var(--gray15);
  line-height: 1.6em;
  font-size: 1.3rem;
  background: var(--gray1);
  border-radius: 0;
  box-shadow: none;
  white-space: inherit;
  word-wrap: normal;
  overflow-x: auto;
  resize: vertical;
  outline: 1px solid var(--gray3);
  border: none;
}
.cdx-checklist {
  --padding: 5px;
  --color-border: #d0d0d0;
  --color-border-hover: #b5b5b5;
  --color-bg-checked: #388ae5;
  --color-bg-checked-hover: #307cd1;
  --color-tick: #fff;
  margin: -0.8em 0; /** To ignore cdx-block's default padding */
}
.cdx-checklist__item {
  display: flex;
  box-sizing: content-box;
}
.cdx-checklist__item-text {
  outline: none;
  flex-grow: 1;
  padding: var(--padding) 0;
}
.cdx-checklist__item-checkbox {
  display: inline-block;
  flex-shrink: 0;
  position: relative;
  width: 20px;
  height: 20px;
  margin: var(--padding);
  margin-left: 0;
  margin-right: 7px;
  border-radius: 50%;
  border: 1px solid var(--color-border);
  background: #fff;
  cursor: pointer;
  user-select: none;
}
.cdx-checklist__item-checkbox:hover {
  border-color: var(--color-border-hover);
}
.cdx-checklist__item-checkbox::after {
  position: absolute;
  top: 6px;
  left: 5px;
  width: 9px;
  height: 4px;
  border: 2px solid var(--color-tick);
  border-top: none;
  border-right: none;
  background: transparent;
  content: "";
  opacity: 0;
  transform: rotate(-45deg);
}
.cdx-checklist__item--checked .cdx-checklist__item-checkbox {
  background: var(--color-bg-checked);
  border-color: var(--color-bg-checked);
}
.cdx-checklist__item--checked .cdx-checklist__item-checkbox::hover {
  background: var(--color-bg-checked-hover);
}
.cdx-checklist__item--checked .cdx-checklist__item-checkbox::after {
  opacity: 1;
}

.cdx-underline {
  text-decoration: underline;
}

.picker_wrapper.popup {
  z-index: 99;
  width: 170px;
  margin: 0;
  box-shadow: 0 0 10px 1px #eaeaea;
  background: #ffffff;
}

.picker_arrow {
  display: none;
}

.layout_default .picker_slider,
.layout_default .picker_selector {
  padding: 5px;
}

.cdx-marker {
  background-color: rgb(255, 202, 60, 0.2);
  padding: 3px 0;
  color: var(--gray17);
}

.inline-code {
  background: rgba(250, 239, 240, 0.78);
  color: #b44437;
  padding: 3px 4px;
  border-radius: 5px;
  margin: 0 1px;
  font-family: inherit;
  font-size: 0.86em;
  font-weight: 500;
  letter-spacing: 0.3px;
}

/* fill math overflow in the shared note */
.katex {
  white-space: pre-line !important;
}
